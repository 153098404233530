import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
// import $ from "jquery"; // not always available
import { HTML_ATTRIBUTE_COOKIE_IDS, OPT_IN_CONTENT_BLOCKER_ALL } from "@devowl-wp/headless-content-unblocker";
var ALREADY_OVERWRITTEN_PROPERTY = "listenOptInJqueryFnForContentBlockerNow";
/**
 * Modify a jQuery function to wait for consent if a content blocker is within the
 * given selector.
 */

function hijackJqueryFn(fns) {
  var $ = window.jQuery;

  if (!($ !== null && $ !== void 0 && $.fn)) {
    return;
  }

  var jQueryFns = $.fn;

  var _iterator = _createForOfIteratorHelper(fns),
      _step;

  try {
    var _loop = function _loop() {
      var fn = _step.value;
      var originalFunction = jQueryFns[fn];

      if (!originalFunction) {
        return "continue";
      } // Already overwritten?


      var alreadyOverwritten = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] || [];

      if (alreadyOverwritten.indexOf(fn) > -1) {
        return "continue";
      }

      alreadyOverwritten.push(fn); // Memorize the static properties (e.g. `jQuery.fn.{fn}.defaults` (https://github.com/marioestrada/jQuery-gMap/blob/33b2771a5789b7531eb56878cfa3e4a0c30a8c43/jquery.gmap.js#L250))

      var staticProps = Object.getOwnPropertyDescriptors(originalFunction);
      delete staticProps.length;
      delete staticProps.name;
      delete staticProps.prototype;

      jQueryFns[fn] = function () {
        var _this = this;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var callOriginal = function callOriginal() {
          return originalFunction.apply($(_this), args);
        };

        if (this.length) {
          return this.each(function () {
            var nodes = Array.prototype.slice.call(this.querySelectorAll("[".concat(HTML_ATTRIBUTE_COOKIE_IDS, "]"))); // Also respect own element

            if (this.getAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
              nodes.push(this);
            } // Call original function immediate if no content blocker found


            if (!nodes.length) {
              callOriginal();
            } else {
              // Wait for all unblocked content
              Promise.all(nodes.map(function (node) {
                return new Promise(function (resolve) {
                  return node.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, resolve);
                });
              })).then(function () {
                return callOriginal();
              });
            }
          });
        } else {
          // There is no jQuery object passed to the jQuery function. But we need to call the
          // original function anyway as some properties of e.g. `$.fn.prettyPhoto` can be
          // lazy loaded and expect a "first" call
          return callOriginal();
        }
      }; // Apply memorized static properties


      Object.defineProperties(jQueryFns[fn], staticProps);
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _ret = _loop();

      if (_ret === "continue") continue;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { hijackJqueryFn };