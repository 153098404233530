import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "@devowl-wp/headless-content-unblocker";
import { MEMORIZE_JQUERY_EVENT_PROPERTY } from ".";
var OVERWRITE_PROPERTY = "rcbNativeEventListener";
/**
 * Overwrite `window.addEventListener('load')` as they can not be triggered by our script blocker.
 * This can also be used for other known events.
 */

function applyNativeEventListenerInitiator(element, eventName) {
  var _Object$assign;

  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    onBeforeExecute: undefined
  },
      onBeforeExecute = _ref.onBeforeExecute;

  var overwriteProp = "".concat(OVERWRITE_PROPERTY, "_").concat(eventName);
  var memorizeProp = "".concat(MEMORIZE_JQUERY_EVENT_PROPERTY, "_").concat(eventName); // Only overwrite once

  if (element[overwriteProp]) {
    return;
  }

  var _addEventListener = element.addEventListener;
  Object.assign(element, (_Object$assign = {}, _defineProperty(_Object$assign, overwriteProp, true), _defineProperty(_Object$assign, "addEventListener", function addEventListener(type) {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    if (type === eventName) {
      var memorizeExecutionPromise = element[memorizeProp];
      var executed = false; // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7

      var executeHandle = function executeHandle() {
        return setTimeout(function () {
          var _rest$;

          onBeforeExecute === null || onBeforeExecute === void 0 ? void 0 : onBeforeExecute();
          (_rest$ = rest[0]) === null || _rest$ === void 0 ? void 0 : _rest$.call(rest, new Event(eventName, {
            bubbles: true,
            cancelable: true
          }));
        }, 0);
      };

      document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, function () {
        // IE does not support the `once` option
        if (executed) {
          return;
        }

        executed = true;

        if (memorizeExecutionPromise) {
          memorizeExecutionPromise.then(executeHandle);
        } else {
          executeHandle();
        }
      });
    } else {
      _addEventListener.apply(this, [type].concat(rest));
    }
  }), _Object$assign));
}

export { applyNativeEventListenerInitiator };