import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { applyContentBlockRatioCssPlainCss, customUnblockTriggers, getCookieConsentManager } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { render, unmountComponentAtNode } from "react-dom";
import { WebsiteBlocker } from "../../components";
import { applyJQueryEventInitiator, UnblockWatcher } from "@devowl-wp/headless-content-unblocker";
import { createTcfModelFromCookie, getUserDecision, OPT_IN_ALL_EVENT, BANNER_PRE_DECISION_SHOW_EVENT, transformTcfSrc } from "@devowl-wp/cookie-consent-web-client";
import { getSsrPoweredByLink } from "@devowl-wp/react-cookie-banner";
/**
 * Listen to opt-in of all cookies so we can enable the blocked content or
 * create an visual content blocker. It also listens if no / or invalid consent is given.
 */

function listenOptInForContentBlocker() {
  var acceptedCookies = [];

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      setVisualParentIfClassOfParent = _getOtherOptionsFromW.setVisualParentIfClassOfParent,
      multilingualSkipHTMLForTag = _getOtherOptionsFromW.multilingualSkipHTMLForTag,
      dependantVisibilityContainers = _getOtherOptionsFromW.dependantVisibilityContainers,
      allBlocker = _getOtherOptionsFromW.blocker,
      tcf = _getOtherOptionsFromW.tcf,
      tcfMetadata = _getOtherOptionsFromW.tcfMetadata,
      userConsentCookieName = _getOtherOptionsFromW.userConsentCookieName,
      pageRequestUuid4 = _getOtherOptionsFromW.pageRequestUuid4;

  var watcher = new UnblockWatcher({
    checker: function checker(by, requiredIds, blockerId) {
      var _allBlocker$filter;

      var blocker = (_allBlocker$filter = allBlocker.filter(function (_ref) {
        var id = _ref.id;
        return id === blockerId;
      })) === null || _allBlocker$filter === void 0 ? void 0 : _allBlocker$filter[0];
      var consent = true;

      if (by === "cookies") {
        consent = requiredIds.map(function (requiredId) {
          var _iterator = _createForOfIteratorHelper(acceptedCookies),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var id = _step.value.cookie.id;

              if (id === requiredId) {
                return true;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          return false;
        }).indexOf(false) === -1;
      }

      if (process.env.IS_TCF === "1" && by === "tcfVendors") {
        var tcfData = createTcfModelFromCookie(tcf, tcfMetadata, getCookieConsentManager().getOption("tcfCookieName"));

        if (!tcfData) {
          // No consent given yet, fallback to our decision (e.g. Country Bypass and GDPR does not apply for TCF)
          var decision = getUserDecision(userConsentCookieName);
          consent = decision && getOtherOptionsFromWindow().revisionHash === decision.revision;
        } else {
          var model = tcfData.model; // Map vendor IDs to "has consent" and detect if all have consent

          consent = requiredIds.map(function (vendorId) {
            return model.vendorConsents.has(vendorId) || model.vendorLegitimateInterests.has(vendorId);
          }).indexOf(false) === -1;
        }
      }

      return {
        consent: consent,
        blocker: blocker
      };
    },
    overwriteAttributeValue: function overwriteAttributeValue(value, originalAttribute) {
      if (process.env.IS_TCF === "1" && originalAttribute === "src") {
        var current = createTcfModelFromCookie(tcf, tcfMetadata, getCookieConsentManager().getOption("tcfCookieName"));
        return transformTcfSrc(value, current === null || current === void 0 ? void 0 : current.tcfStringForVendors, current === null || current === void 0 ? void 0 : current.gvl);
      }

      return value;
    },
    transactionClosed: function transactionClosed(unblockedNodes) {
      customUnblockTriggers(unblockedNodes);
    },
    visual: {
      setVisualParentIfClassOfParent: setVisualParentIfClassOfParent,
      dependantVisibilityContainers: dependantVisibilityContainers,
      unmount: function unmount(contentBlocker) {
        unmountComponentAtNode(contentBlocker);
      },
      mount: function mount(_ref2) {
        var container = _ref2.container,
            blocker = _ref2.blocker,
            onClick = _ref2.onClick,
            thumbnail = _ref2.thumbnail,
            paintMode = _ref2.paintMode;

        // Do not translate this content blocker with an output buffer plugin like TranslatePress or Weglot
        if (multilingualSkipHTMLForTag) {
          container.setAttribute(multilingualSkipHTMLForTag, "1");
        }

        var blockerDef = _objectSpread(_objectSpread({}, blocker), {}, {
          visualThumbnail: thumbnail || blocker.visualThumbnail
        });

        render(h(WebsiteBlocker, {
          poweredLink: getSsrPoweredByLink("".concat(pageRequestUuid4, "-powered-by")),
          blocker: blockerDef,
          paintMode: paintMode,
          setVisualAsLastClickedVisual: onClick
        }), container);
      }
    },
    customInitiators: function customInitiators(ownerDocument, defaultView) {
      // [Plugin comp]: TODO: extract to configuration
      applyJQueryEventInitiator(ownerDocument, defaultView, "elementor/frontend/init");
      applyJQueryEventInitiator(ownerDocument, defaultView, "tcb_after_dom_ready"); // Thrive Architect

      applyJQueryEventInitiator(ownerDocument, ownerDocument, "mylisting/single:tab-switched");
      applyJQueryEventInitiator(ownerDocument, ownerDocument, "tve-dash.load", {
        // Mark requests as already sent as blocked modules in Thrive never gets loaded again
        onBeforeExecute: function onBeforeExecute() {
          var _ref3 = window,
              TVE_Dash = _ref3.TVE_Dash;
          TVE_Dash.ajax_sent = true;
        }
      }); // Thrive Leads
    },
    delegateClick: {
      same: [// Ultimate Video (WP Bakery Page Builder)
      ".ultv-video__play", // Elementor
      ".elementor-custom-embed-image-overlay", // Themify
      ".tb_video_overlay", // Premium Addons for Elementor
      ".premium-video-box-container", // https://themeforest.net/item/norebro-creative-multipurpose-wordpress-theme/20834703
      ".norebro-video-module-sc", // WP Video Lightbox
      'a[rel="wp-video-lightbox"]', // WP YouTube Lyte
      '[id^="lyte_"]', // https://github.com/paulirish/lite-youtube-embed
      "lite-youtube", // https://github.com/luwes/lite-vimeo-embed
      "lite-vimeo", // https://avada.theme-fusion.com/design-elements/lightbox-element/
      ".awb-lightbox"],
      nextSibling: [// JetElements for Element
      ".jet-video__overlay"],
      parentNextSibling: [// Divi Page Builder
      ".et_pb_video_overlay"]
    }
  });
  document.addEventListener(OPT_IN_ALL_EVENT, function (_ref4) {
    var cookies = _ref4.detail.cookies;
    acceptedCookies = cookies;
    watcher.unblockNow();
    watcher.start();
  });
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, function () {
    acceptedCookies = [];
    watcher.unblockNow();
    watcher.start();
  });
  applyContentBlockRatioCssPlainCss();
}

export { listenOptInForContentBlocker };