import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";

/**
 * A content got unblocked, let's run custom triggers on them, for example
 * the Elementor ready hook.
 */
function customUnblockTriggers(nodes) {
  var _ref = window,
      elementorFrontend = _ref.elementorFrontend,
      TCB_Front = _ref.TCB_Front,
      jQuery = _ref.jQuery,
      showGoogleMap = _ref.showGoogleMap,
      et_pb_init_modules = _ref.et_pb_init_modules,
      et_calculate_fullscreen_section_size = _ref.et_calculate_fullscreen_section_size,
      tdYoutubePlayers = _ref.tdYoutubePlayers,
      tdVimeoPlayers = _ref.tdVimeoPlayers,
      FWP = _ref.FWP,
      avadaLightBoxInitializeLightbox = _ref.avadaLightBoxInitializeLightbox;
  var foundWpgb = false;

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var node = _step.value.node;
      var className = node.className,
          id = node.id; // Elementor https://git.io/JsXoh

      elementorFrontend === null || elementorFrontend === void 0 ? void 0 : elementorFrontend.elementsHandler.runReadyTrigger(node); // WP Grid Builder

      if (id.startsWith("wpgb-") || className.startsWith("wpgb-")) {
        foundWpgb = true;
      } // Thrive Visual Editor


      if (TCB_Front && jQuery && className.indexOf("tcb-yt-bg") > -1) {
        var $node = jQuery(node);

        if ($node.is(":visible")) {
          TCB_Front.playBackgroundYoutube($node);
        }
      }
    } // Thrive Architect

  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  TCB_Front === null || TCB_Front === void 0 ? void 0 : TCB_Front.handleIframes(TCB_Front.$body, true); // https://avada.theme-fusion.com/design-elements/lightbox-element/

  avadaLightBoxInitializeLightbox === null || avadaLightBoxInitializeLightbox === void 0 ? void 0 : avadaLightBoxInitializeLightbox(); // FacetWP

  if (FWP) {
    FWP.loaded = false;
    FWP.refresh();
  } // https://qodeinteractive.com/wordpress-theme/bridge-creative-multi-purpose-wordpress-theme/


  showGoogleMap === null || showGoogleMap === void 0 ? void 0 : showGoogleMap(); // jQuery plugins

  if (jQuery) {
    var _jQuery$lazyLoadXT, _jQuery;

    // https://github.com/ressio/lazy-load-xt
    (_jQuery$lazyLoadXT = (_jQuery = jQuery(window)).lazyLoadXT) === null || _jQuery$lazyLoadXT === void 0 ? void 0 : _jQuery$lazyLoadXT.call(_jQuery);
  } // Divi modules (e.g. Contact Forms and Newsletter signups)


  if (et_pb_init_modules) {
    // We need to reset the resize event listener as it results in an "resize" loop:
    // https://wordpress.org/support/topic/ultimatemember-profile-picture-upload-flickering/page/2/#post-15471580
    jQuery(window).off("resize", et_calculate_fullscreen_section_size);
    et_pb_init_modules();
  } // tagDiv Composer


  tdYoutubePlayers === null || tdYoutubePlayers === void 0 ? void 0 : tdYoutubePlayers.init();
  tdVimeoPlayers === null || tdVimeoPlayers === void 0 ? void 0 : tdVimeoPlayers.init(); // WP Grid Builder (the custom event triggers an AJAX request, so do this only if we unblock a WPGB element)

  try {
    foundWpgb && window.dispatchEvent(new CustomEvent("wpgb.loaded"));
  } catch (e) {// Silence is golden.
  }
}

export { customUnblockTriggers };